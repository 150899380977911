import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import AuthAxios from "../../config/authAxios"


const initialState = {
    result: [],
    loading: false,
    error: false,
    message: ""
}

export const currentUserAPI = createAsyncThunk(
    'auth/current-user',
    async (data, { rejectWithValue }) => {
        try {
            const response = await AuthAxios.get(`api/v1/auth/current-user/`)
            return response?.data
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

const currentUserSlice = createSlice({
    name: 'current-user',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(currentUserAPI.pending, (state, action) => {
            state.loading = true
        })
        builder.addCase(currentUserAPI.fulfilled, (state, action) => {
            state.loading = false
            state.result = action.payload.result
            state.message = action.payload.message
        })
        builder.addCase(currentUserAPI.rejected, (state, action) => {
            state.loading = false
            state.error = action.payload.error
            state.message = action.payload.message
        })
    },
    reducers: {}
})


export const currentUserReducer = currentUserSlice.reducer