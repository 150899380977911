import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { reset } from "../../store/courses/courseDetails";
import { courseApi } from "../../store/courses/coursesList";
import DeleteCourse from "./DeleteCourse";
import PublishCourse from "./PublishCourse";
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import { resetSection } from "../../store/courses/getSection";
import DataGridComponent from "../../components/DataGridComponent";

export default function Courses() {

  const courses = useSelector((state) => state?.courses);
  const { result, loading, total } = courses;
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDownload, setIsDownload] = useState(false)

  const [selectedRow, setSelectedRow] = useState();
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const columns = [
    {
      field: "course_id",
      headerName: "Course Id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "name",
      headerName: "Content Name",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 150,
    },
    {
      field: "start_display",
      headerName: "Starts",
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: (rowData) => (
        <>
          <Typography>{new Date(rowData.row.start).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
          })}</Typography>
        </>
      ),
    },
    {
      field: "key_takeaways",
      headerName: "Key Takeaways",
      filterable: false,
      sortable: false,
      width: 250,
    },
    {
      field: "org",
      headerName: "Organisation",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "number",
      headerName: "Course Number",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "category",
      headerName: "Category",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals',
      ),
      sortable: false,
      width: 150,
    },
    {
      field: "pacing",
      headerName: "Pacing Type",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "blocks_url",
      headerName: "Blocks Url",
      filterable: false,
      sortable: false,
      width: 300,
    },
    {
      field: "publish",
      headerName: "Publish Course",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            // disabled
            color="info"
            onClick={() => {
              setNotSelectedProductModel(true)
              setDeleteId(row.id)
              setSelectedRow(row.row)
            }}
            endIcon={<ArrowRightAltIcon />}>
            {row.row.published ? "Unpublish" : "Publish"}

          </Button>
        </>
      ),
    },
    {
      field: "update_courese",
      headerName: "Update Courese",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => navigate(`/edit-course/${row.row.course_id}`)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_courese",
      headerName: "Delete Course",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            disabled
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];



  const addToCartHandler = async () => {
    navigate("/add-courses")
  };

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(courseApi(query));
    dispatch(reset());
    dispatch(resetSection())

  }, [dispatch, queryOptions, paginationModel]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Courses List</Typography>
        </Stack>

        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addToCartHandler}
              variant="contained">
              ADD New Course
            </Button>
          </Stack>
        </Stack>

        <Paper elevation={0}>
          <DataGridComponent
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 30]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sx={{
                    height: 500 
                }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "coursesManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.courseList })),
            }}
            slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>


      </Body>
      <PublishCourse
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        selectedRow={selectedRow}
        id={deleteId}
      />
      <BackdropUI open={isDownload} />
    </>
  );
}
