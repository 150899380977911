import React, { PureComponent, useState } from "react";
import { Box } from "@mui/material";
import { DataGrid, GridToolbar, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport, GridToolbarExportContainer, getGridStringOperators, GridToolbarQuickFilter } from "@mui/x-data-grid";

import {
  MenuItem
} from "@mui/material";


export default function CustomGridToolbar(props) {
  const { apiRef,
    queryOptions,
    paginationModel,
    isDownload,
    setIsDownload,
    apiUrl,
    module,
    region,
    state,
    startDate,
    endDate,
    gender } = props
  const handleApiCall = async () => {
    try {
      const filterModel = queryOptions?.filterModel;
      const sortModel = queryOptions?.sortModel;
      const items = filterModel?.items;
      const firstItem = items && items.length > 0 ? items[0] : {};
      const quickFilterValues = filterModel?.quickFilterValues;
      let query
      if (sortModel?.length > 0) {
        query = {
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
          sort_order: sortModel[0].sort || "desc",
          module_name: module || '',
          city: region || '',
          state: state || '',
          gender: gender || '',
          startDate: startDate || '',
          endDate: endDate || '',
          field: firstItem.field || (gender ? "gender" : '') || '',
          operator: firstItem.operator || (quickFilterValues && "contains") || '',
          value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || gender || ''
        };
      } else {
        query = {
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
          sort_order: "desc",
          module_name: module || '',
          city: region || '',
          state: state || '',
          gender: gender || '',
          startDate: startDate || '',
          endDate: endDate || '',
          field: firstItem.field || (gender ? "gender" : '') || '',
          operator: firstItem.operator || (quickFilterValues && "contains") || '',
          value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || gender || ''
        };
      }
      const obj = {
        csv: true,
        file_type: "csv",
        sort_order: "desc",
        ...query
      };
      const queryParameters = new URLSearchParams(obj);
      const queryString = queryParameters.toString();
      const apiUrlWithParams = `${process.env.REACT_APP_BASE_URL}${apiUrl}?${queryString}`;
      setIsDownload(true)
      const response = await fetch(apiUrlWithParams, {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem("access_token"),
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        setIsDownload(false)
        throw new Error('Network response was not ok');
      }
      var fileName
      let contentDisposition = response.headers.get('Content-Disposition')
      if (contentDisposition) {
        // Use regex to capture the filename
        const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);

        if (fileNameMatch && fileNameMatch[1]) {
          fileName = fileNameMatch[1];
          console.log("Filename:", fileName);
        } else {
          console.log("Filename not found in Content-Disposition header.");
        }
      } else {
        console.log("Content-Disposition header not present.");
      }
      const blob = await response.blob();

      // Create a URL for the file
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      if (fileName && fileName !== '') {
        link.setAttribute('download', `${fileName}`)
      } else {
        link.setAttribute('download', `${props?.moduleType}_report_${new Date().getTime()}.csv`); // Specify the file name
      }
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setIsDownload(false)
    } catch (error) {
      console.error('Error exporting data:', error);
      setIsDownload(false)
      alert('Failed to export data.');
    }
  };
  function CustomExportButton({ }) {

    return (
      <MenuItem onClick={handleApiCall}>
        Export to CSV
      </MenuItem>
    );
  }
  function CustomToolbarExport(props) {
    const { apiRef } = props;

    return (
      <GridToolbarExportContainer>
        <CustomExportButton apiRef={apiRef} />
      </GridToolbarExportContainer>
    );
  }
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector
        slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    }, tooltip: { title: 'Change density' } }}
      />
      <CustomToolbarExport apiRef={apiRef} />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter debounceMs={1500} />
    </GridToolbarContainer>
  );
}
