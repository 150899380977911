import { getGridStringOperators } from "@mui/x-data-grid";
import {
  Button,
  Card,
  Paper,
  Grid,
  Stack,
  Typography,
  Box,
  Menu,
  MenuItem
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useParams } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import React, { useState, useEffect, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { sundayPollResultsApi } from "../../store/sundayPolls/result";
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import DataGridComponent from "../../components/DataGridComponent";

export default function SundayPollAnalytics() {
  const sundayPollResult = useSelector((state) => state?.sundayPollResult);
  const userCount = useSelector((state) => state?.userCount?.result);
  const recentUserCount = useSelector((state) => state?.recentUserCount?.result);
  const { result: tableResult, loading: tableLoading, total } = sundayPollResult;
  const [selectedGender, setSelectedGender] = useState();
  const [selectedGenderName, setSelectedGenderName] = useState("");
  const [socialOpen1, setSocialOpen1] = useState(false);
  const [socialAnchor1, setSocialAnchor1] = useState(null)
  const [queryOptions, setQueryOptions] = useState({});
  const [isDownload, setIsDownload] = useState(false)
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const dispatch = useDispatch();
  const params = useParams();

  const handleGender = (event, val) => {
    setSelectedGender(val)
    setSelectedGenderName(event.target.innerText)
    setSocialOpen1(false);
  }

  const resetFilter = () => {
    setSelectedGenderName("")
    setSelectedGender()
  }

  const handleToggleSocial1 = (event) => {
    // if(token){
    setSocialAnchor1(event.currentTarget)
    setSocialOpen1((prev) => !prev)
    // }
  };

  const handleCloseSocial1 = () => {
    setSocialOpen1(false);
  };

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;
    const sortModel = queryOptions?.sortModel;
    let query
    if (params.id) {
      if (sortModel?.length > 0) {
        query = {
          id: params.id,
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
          sort_order: sortModel[0].sort || "desc",
          field: firstItem.field || (selectedGender ? "gender" : '') || '',
          operator: firstItem.operator || (quickFilterValues && "contains") || '',
          value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || selectedGender || ''
        };
      } else {
        query = {
          id: params.id,
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
          field: firstItem.field || (selectedGender ? "gender" : '') || '',
          sort_order: "desc",
          operator: firstItem.operator || (quickFilterValues && "contains") || '',
          value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || selectedGender || ''
        };
      }
      dispatch(sundayPollResultsApi(query));
    }
  }, [dispatch, queryOptions, paginationModel, params.id, selectedGender]);

  const getRowSpacing = useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const handleSortModelChange = useCallback((sortModel) => {
    setQueryOptions({ sortModel: [...sortModel] });
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "User Id",
      width: 100,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
    },
    {
      field: "fullname",
      headerName: "Full Name",
      width: 200,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
    },
    {
      field: "poll_option_selected",
      headerName: "Selected Option",
      width: 350,
      filterable: false,
      sortable: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
    },
  ];
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack spacing={2} justifyContent={"end"} direction={"row"}>
              <Button
                className='dropdown-btn'
                onClick={(event) => handleToggleSocial1(event)}
                endIcon={<KeyboardArrowDownIcon />}
              >
                {selectedGenderName || "Gender"}
              </Button>
              <Menu
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
                open={socialOpen1} anchorEl={socialAnchor1} onClose={handleCloseSocial1} elevation={0}>
                {/* {months.map((month: any, idx: number)=>(
                      <MenuItem selected={month===selectedMonth} key={idx} value={month.number} onClick={(e)=>handleGender(e,month.number)}>
                        <Typography>{month.name}</Typography>
                        </MenuItem>
                      )
                    )} */}
                <MenuItem value={"male"} onClick={(e) => handleGender(e, "male")}>
                  <Typography>Male</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography value={"female"} onClick={(e) => handleGender(e, "female")}>Female</Typography>
                </MenuItem>
                <MenuItem value={"other"} onClick={(e) => handleGender(e, "other")}>
                  <Typography>Other</Typography>
                </MenuItem>
              </Menu>
              <Button
                className='reset-btn'
                onClick={resetFilter}
                variant="contained"
              >
                Reset
              </Button>
            </Stack>
            <Stack spacing={2}>
              <Card sx={{ py: 2 }}>
                <Typography fontWeight={600} variant='h5' my={1}>Poll Question</Typography>
                <Grid container rowGap={2}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className='customCard7'
                    // onClick={handleShowNotification}
                    >
                      <Stack direction='row' alignItems='center' spacing={1}>
                        <Typography>{tableResult?.poll_details?.poll_question}</Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              </Card>
              <Card sx={{ py: 2 }}>
                {/* <Typography fontWeight={600} variant='h5' my={1}>Result</Typography> */}
                <Typography fontWeight={600} variant='h5' my={1}>Total Participants {tableResult?.total_response}</Typography>
                <Grid container rowGap={2}>
                  <Grid item xs={6} sm={6} md={6} lg={3}>
                    <Box className='customCard7'
                    // onClick={handleShowNotification}
                    >
                      <Stack direction='row' alignItems='center' spacing={1}>
                        {/* <AiOutlineBell size={25} /> */}
                        <Stack >
                          <Typography>{tableResult?.option_response?.poll_option_1?.option}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Votes {tableResult?.option_response?.poll_option_1?.total_vote}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Percentage {Number(tableResult?.option_response?.poll_option_1?.percentage_vote).toFixed(2)}%</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={3}>
                    <Box className='customCard7'>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        {/* <AiOutlineBell size={25} /> */}
                        <Stack >
                          <Typography>{tableResult?.option_response?.poll_option_2?.option}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Votes {tableResult?.option_response?.poll_option_2?.total_vote}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Percentage {Number(tableResult?.option_response?.poll_option_2?.percentage_vote).toFixed(2)}%</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={3}>
                    <Box className='customCard7'>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        {/* <AiOutlineBell size={25} /> */}
                        <Stack >
                          <Typography>{tableResult?.option_response?.poll_option_3?.option}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Votes {tableResult?.option_response?.poll_option_3?.total_vote}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Percentage {Number(tableResult?.option_response?.poll_option_3?.percentage_vote).toFixed(2)}%</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={3}>
                    <Box className='customCard7'>
                      <Stack direction='row' alignItems='center' spacing={1}>
                        {/* <AiOutlineBell size={25} /> */}
                        <Stack >
                          <Typography>{tableResult?.option_response?.poll_option_4?.option}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Votes {tableResult?.option_response?.poll_option_4?.total_vote}</Typography>
                          <Typography variant='h4' fontWeight={600}>Total Percentage {Number(tableResult?.option_response?.poll_option_4?.percentage_vote).toFixed(2)}%</Typography>
                        </Stack>
                      </Stack>
                    </Box>
                  </Grid>

                </Grid>
              </Card>
            </Stack >
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Stack>
              <Paper elevation={0}>
                <DataGridComponent
                  loading={tableLoading}
                  rowCount={total}
                  pageSize={paginationModel?.pageSize}
                  onPageSizeChange={(newPageSize) => {
                    setPaginationModel({
                      ...paginationModel,
                      pageSize: newPageSize,
                    })
                  }}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                  sx={{
                    height: 500 
                }}
                  getRowId={(row) => row.id}
                  rows={tableResult?.participant_details || []}
                  getRowSpacing={getRowSpacing}
                  columns={columns}
                  onSortModelChange={handleSortModelChange}
                  onFilterModelChange={onFilterChange}
                  slots={{
                    noRowsOverlay: () => (
                      <Box className="noData">
                        <img src={NodataImg} alt="no data found" />
                      </Box>
                    ),
                    toolbar: (props) =>
                      (CustomGridToolbar({ ...props, moduleType: "sundayPollAnalytics", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.sundayPollsAction(`poll-analytics/${params.id}`, 1), gender: selectedGender }))
                    ,
                  }}
                  slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Paper>
            </Stack>
          </Grid>
        </Grid>

      </Body>
    </>
  );
}
