import { Card, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { webinarInterestedApi } from "../../store/webinar/interestedUsers";
import { useParams } from "react-router-dom";
import DataGridComponent from "../../components/DataGridComponent";

export default function ManageWebinar() {
  const params = useParams();

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const dispatch = useDispatch();
  const columns = [
    { field: "user_name", headerName: "User Name", width: 150 },
    { field: "fullname", headerName: "Full Name", width: 150 },
    { field: "email", headerName: "email", width: 150 },
    { field: "gender", headerName: "gender", width: 150 },
    { field: "phone_number", headerName: "phone_number", width: 150 },
    { field: "school_name", headerName: "school_name", width: 150 },
    { field: "city", headerName: "city", width: 150 },

    { field: "state", headerName: "state", width: 150 },
    { field: "pincode", headerName: "pincode", width: 150 },
  ];
  const webinarInterestedUser = useSelector(
    (state) => state?.webinarInterestedUser
  );
  useEffect(() => {
    dispatch(
      webinarInterestedApi({
        page: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        id: params?.id,
      })
    );
  }, [dispatch, paginationModel?.page, paginationModel?.pageSize, params?.id]);


  console.log("paginationModel", paginationModel);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Intrested User List</Typography>
        </Stack>

        <Card sx={{ py: 2 }}>
          {/* <Typography fontWeight={600} variant='h5' my={1}>Result</Typography> */}
          <Typography fontWeight={600} variant="h5" my={1}>
            Total Interested Users : {webinarInterestedUser?.total}{" "}
          </Typography>
        </Card>

        <Paper elevation={0}>
          <DataGridComponent
            sx={{ height: 400, marginTop: 2 }}
            rows={
              webinarInterestedUser?.result?.map((user, index) => ({
                id: index,
                user_name: user.username || "",
                fullname: user.fullname || "N/A",
                email: user.email || "N/A",
                gender: user.gender || "N/A",
                phone_number: user.phone_number || "N/A",
                school_name: user.school_name || "N/A",
                city: user.city || "N/A",
                state: user.state || "N/A",
                pincode: user.pincode || "N/A",
              })) || []
            }
            columns={columns}
            loading={webinarInterestedUser.loading}
            rowCount={webinarInterestedUser.total}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Paper>
      </Body>
    </>
  );
}
