import { Box, Paper, Stack, Typography } from "@mui/material";
import { getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import { useContext, useEffect, useState, useCallback } from "react";
import { HiTemplate } from "react-icons/hi";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { pblApproveApi } from "../../store/pbl/approvePbl";
import { ThemeContext } from '../../context/ThemeContext';
import { cohortUserApi } from '../../store/notification-cohort/cohortUserList';
import DataGridComponent from "../../components/DataGridComponent";

export default function ManageCohortUser() {
  const navigate = useNavigate();
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const podcastSelector = useSelector((state) => state?.cohortUser);
  const pblApprove = useSelector((state) => state?.pblApprove);
  const { result, loading } = podcastSelector;
  const [queryOptions, setQueryOptions] = useState({});
  const params = useParams();

  const onFilterChange = useCallback((filterModel) => {
    // Here you save the data you need from the filter model
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const dispatch = useDispatch();


  const columns = [

    {
      field: "fullname",
      headerName: "Full Name",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 250,
    },
    {
      field: "gender",
      headerName: "Gender",
      filterable: false,
      sortable: false,
      width: 200,
    },
    {
      field: "city",
      headerName: "City ",
      filterable: false,
      sortable: false,
      width: 200,
    },
    {
      field: "state",
      headerName: "State",
      filterable: false,
      sortable: false,
      width: 200,
    },
    {
      field: "email",
      headerName: "Email",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 250,
    },
    {
      field: "school_name",
      headerName: "School Name",
      filterable: false,
      sortable: false,
      width: 200,
    },
  ];

  const addToCartHandler = async (id, approve) => {
    var obj = {
      project_based_id: id,
      is_approved: !approve,
    }
    await dispatch(
      pblApproveApi(obj)
    );
    if (!pblApprove?.loading) {
      dispatch(
        cohortUserApi(
          {
            id: params.id,
            page: paginationModel?.page + 1,
            page_size: paginationModel?.pageSize,
          }
        )
      );

    }
  };

  useEffect(() => {
    dispatch(cohortUserApi({
      id: params.id,
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
    }));
  }, [dispatch, paginationModel]);
  const addEntry = async () => {
    navigate("/add-pbl-management");
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Cohort User List</Typography>
        </Stack>
        {/* <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addEntry}
              variant="contained"
            >
              Add New
            </Button>

          </Stack>
        </Stack> */}
        <Paper elevation={0}>
          <DataGridComponent
            loading={podcastSelector.loading}
            rowCount={podcastSelector.total}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sx={{
                    height: 500 
                }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: GridToolbar,
            }}
            slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>
      {/* <Deletepbl
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      /> */}

    </>
  );
}
