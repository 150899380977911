import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { resetPodcast } from "../../store/podcast/podcastDetails";
import { podcastApi } from "../../store/podcast/podcastList";
import DeletePodcast from "./DeletePodcast";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import BulkPodcast from "./BulkPodcast";
import { ThemeContext } from '../../context/ThemeContext';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import PublishUnpublishModule from "../../components/PublishUnpublishModule";
import DataGridComponent from "../../components/DataGridComponent";

export default function ManagePodcast() {
  const podcastSelector = useSelector((state) => state?.podcast);
  const { result, loading, total } = podcastSelector;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isDownload, setIsDownload] = useState(false)
  const [selectedRow, setSelectedRow] = useState([]);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [publishModel, setPublishModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [podcastBulk, setPodcastBulk] = useState(false);
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [queryOptions, setQueryOptions] = useState({});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "Podcast Id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "title",
      headerName: "Title",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },

    {
      field: "allocate_stars",
      headerName: "Stars",
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      field: "video_url",
      headerName: "Video Url",
      filterable: false,
      sortable: false,
      width: 400,
    },
    {
      field: "image",
      headerName: "Thumbnail Image",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => (
        <Box sx={{ py: 1 }}><img width={200} height={100} src={params.row.banner} alt='banner image' /></Box>)
    },
    {
      field: "category",
      headerName: "Category",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 150,
    },
    {
      field: "update_podcast",
      headerName: "Update Podcast",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => navigate(`/edit-podcast/${row.id}`)}

          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_podcast",
      headerName: "Delete Podcast",
      filterable: false,
      sortable: false,
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true)
              setDeleteId(row.id)
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
    {
      field: "publish",
      headerName: "Publish Podcast",
      filterable: false,
      sortable: false,
      width: 200,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            label="Available" variant="outlined"
            onClick={() => {
              setPublishModel(true)
              setSelectedRow(row.row)
            }}
            endIcon={<ArrowRightAltIcon />}
          >
            {row.row.published ? "Unpublish" : "Publish"}

          </Button>
        </>
      ),
    },
  ];

  const addToCartHandler = async () => {
    navigate("/add-podcast");
  };

  const getPodcastList = useCallback((queryOptions, paginationModel) => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(podcastApi(query));
  }, [dispatch])

  useEffect(() => {
    getPodcastList(queryOptions, paginationModel)
    dispatch(resetPodcast());
  }, [dispatch, queryOptions, paginationModel, getPodcastList]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Podcast List</Typography>
        </Stack>

        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addToCartHandler}
              variant="contained"
            >
              Add New PodCast
            </Button>
            <Button
              onClick={() => setPodcastBulk(true)}
              variant="contained"
            >
              Add Bulk PodCast
            </Button>
          </Stack>
        </Stack>

        <Paper elevation={0}>
          <DataGridComponent
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{
                    height: 500 
                }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "podcastManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.podcastsList })),
            }}
            slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>

      <DeletePodcast
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
      <BulkPodcast
        open={podcastBulk}
        setOpen={setPodcastBulk}

      />
      <PublishUnpublishModule
        open={publishModel}
        setOpen={setPublishModel}
        selectedRow={selectedRow}
        queryOptions={queryOptions}
        paginationModel={paginationModel}
        moduleType={"podcast"}
        moduleList={getPodcastList}
      />
      <BackdropUI open={isDownload} />
    </>
  );
}
