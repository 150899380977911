import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DoneIcon from "@mui/icons-material/Done";
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useContext, useEffect, useState, useCallback } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { tlmApproveApi } from "../../store/tlm/approveTlm";
import { tlmApi } from "../../store/tlm/tlmList";
import { useNavigate } from "react-router-dom";
import DeleteTlm from './DeleteTlm'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/Delete';
import { ThemeContext } from "../../context/ThemeContext";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import DataGridComponent from "../../components/DataGridComponent";
export default function ManageTlm() {
  const navigate = useNavigate();

  const { paginationModel, setPaginationModel } = useContext(ThemeContext)

  const [isDownload, setIsDownload] = useState(false)
  const podcastSelector = useSelector((state) => state?.tlmList);
  const tlmApprove = useSelector((state) => state?.tlmApprove);
  const { result, loading } = podcastSelector;
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const columns = [
    {
      field: "title",
      headerName: "Title",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 250,
    },
    {
      field: "topics",
      headerName: "Topics",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.topics) && data?.row?.topics.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "tlm_class",
      headerName: "Class",
      filterable: false,
      sortable: false,
      width: 200,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.tlm_class) && data?.row?.tlm_class.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },
    {
      field: "cost_of_making",
      headerName: "Cost of making ",
      filterable: false,
      sortable: false,
      width: 200,
    },
    {
      field: "description",
      headerName: "Description",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 200,
    },
    {
      field: "how_to_make",
      headerName: "How to Make",
      filterable: false,
      sortable: false,
      width: 200,
    },
    {
      field: "material_used",
      headerName: "Material Used",
      filterable: false,
      sortable: false,
      width: 250,
    },
    {
      field: "subjects",
      headerName: "subjects",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (data) => (<>
        <Typography sx={{ textWrap: "wrap" }}>{Array.isArray(data?.row?.subjects) && data?.row?.subjects.map((obj, index) => (`${obj['title']}, `))}</Typography>
      </>)
    },

    {
      field: "action",
      headerName: "Action",
      filterable: false,
      sortable: false,
      width: 500,
      disableExport: true,
      renderCell: (row) => (
        <div >
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => navigate(`/edit-tlm-management/${row.row.id}`)}
          >
            EDIT
          </Button>
          <Button
            color="error"
            sx={{ margin: "0px 10px" }}

            endIcon={<DeleteIcon />}
            variant="outlined"
            onClick={() => {
              setNotSelectedProductModel(true)
              setDeleteId(row.id)
            }}
          >
            Delete
          </Button>
          <Button
            endIcon={<ArrowDownwardIcon />}
            color="error"
            sx={{ margin: "0px 10px" }}

            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              // console.log("row",row)
              window.open(row.row.file);
            }}
          >
            Download
          </Button>
          <Button
            color="info"
            sx={{ margin: "0px 10px" }}

            endIcon={<DoneIcon />}
            label="Available"
            variant="outlined"
            onClick={() => addToCartHandler(row.row.id, row.row.is_approved)}
          >
            {row.row.is_approved ? "Disapprove" : "Approve"}
          </Button>
        </div>
      ),
    },
  ];

  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);

  const addToCartHandler = async (id, approve) => {
    var obj = {
      learning_material_id: id,
      is_approved: !approve,
    };
    await dispatch(tlmApproveApi(obj));
    if (!tlmApprove?.loading) {
      dispatch(
        tlmApi({
          page: paginationModel?.page + 1,
          page_size: paginationModel?.pageSize,
        })
      );

    }
  };

  const addEntry = async () => {
    navigate("/add-tlm-management");
  };
  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(
      tlmApi(query)
    );
  }, [dispatch, paginationModel, queryOptions]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>
        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>TLM List</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button onClick={addEntry} variant="contained">
              Add New
            </Button>
          </Stack>
        </Stack>
        <Paper elevation={0}>
          <DataGridComponent
            sx={{
                    height: 500 
                }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            loading={podcastSelector.loading}
            rowCount={podcastSelector.total}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "tlmManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.learningMaterialList })),
            }}
            slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>
      <DeleteTlm
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        id={deleteId}
      />
    </>
  );
}
