import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { getGridStringOperators } from "@mui/x-data-grid";
import { useContext, useEffect, useState, useCallback } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useParams, useNavigate } from "react-router-dom";
import { pblApproveApi } from "../../store/pbl/approvePbl";
import { ThemeContext } from '../../context/ThemeContext';
import EditIcon from "@mui/icons-material/Edit";
import { cohortApi } from '../../store/notification-cohort/getAllCohort';
import { resetcohort } from '../../store/notification-cohort/notificationById';
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DataGridComponent from "../../components/DataGridComponent";

export default function ManageNotificationCohort() {
  const navigate = useNavigate();
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const notificationCohurt = useSelector((state) => state?.cohortList);
  const pblApprove = useSelector((state) => state?.pblApprove);
  const [isDownload, setIsDownload] = useState(false)
  const { result, loading, total } = notificationCohurt;
  const [queryOptions, setQueryOptions] = useState({});
  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const dispatch = useDispatch();
  const columns = [

    {
      field: "id",
      headerName: "Cohort id",
      filterable: false,
      sortable: false,
      width: 150,
    },
    {
      field: "title",
      headerName: "Title",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 200,
    },
    {
      field: "message",
      headerName: "Message",
      filterable: false,
      sortable: false,
      width: 300,
    },
    {
      field: "actions",
      headerName: "Actions",
      filterable: false,
      sortable: false,
      width: 400,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available"
            variant="outlined"
            sx={{ margin: "0px 10px" }}
            onClick={() => navigate(`/edit-notification-cohort/${row.row.id}`)}
          >
            EDIT
          </Button>
          {row.row.user_ids.length > 0 ?
            <Button
              endIcon={<ArrowDownwardIcon />}
              color="error"
              variant="outlined"
              sx={{ margin: "0px 10px" }}

              onClick={() => navigate(`/notification-cohort/${row.row.id}/user-list`)}
            >
              User List
            </Button>
            : null}
        </>
      ),
    },



  ];

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(cohortApi(query));
    dispatch(resetcohort());

  }, [dispatch, queryOptions, paginationModel]);
  const addEntry = async () => {
    navigate("/add-notification-cohort");
  };
  return (
    <>
      <Appbar />
      <Drawer />
      <Body>

        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Cohort Notification History</Typography>
        </Stack>
        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addEntry}
              variant="contained"
            >
              Add New
            </Button>

          </Stack>
        </Stack>
        <Paper elevation={0}>
          <DataGridComponent
            loading={loading}
            rowCount={total}
            pageSizeOptions={[5, 10, 20, 50, 100]}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pagination
            paginationMode="server"
            sx={{
                    height: 500 
                }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            filterMode="server"
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "cohortnotificationManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.cohortList })),
            }}
            slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>
      </Body>
      <BackdropUI open={isDownload} />

    </>
  );
}
