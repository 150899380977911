import { Box, Button, Chip, Paper, Stack, Typography } from "@mui/material";
import { DataGrid, getGridStringOperators, GridToolbar } from "@mui/x-data-grid";
import PropTypes from "prop-types";
import { useEffect, useState, useCallback, useContext } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import { ThemeContext } from '../../context/ThemeContext';
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteSchoolResource from "./DeleteSchoolResource";
import { schoolResourceApi } from "../../store/schoolResources/schoolResourceList";
import { resetschoolResource } from "../../store/schoolResources/schoolResourceDetails";
import BulkSchoolResource from "./BulkSchoolResource";
import CustomGridToolbar from "../../components/CustomGridToolbar";
import apiRoutes from "../../store/apiConfigs";
import BackdropUI from "../../ui/Backdrop";
import DataGridComponent from "../../components/DataGridComponent";

export default function ManageSchoolResource() {
  const schoolResource = useSelector((state) => state?.schoolResource);
  const { result, loading, total } = schoolResource;
  const [deleteId, setDeleteId] = useState(null);
  const [podcastBulk, setPodcastBulk] = useState(false);
  const { paginationModel, setPaginationModel } = useContext(ThemeContext)
  const [isDownload, setIsDownload] = useState(false)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState([]);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);
  const [bulkShortVideo, setBulkShortVideo] = useState(false);
  const columns = [

    {
      field: "title",
      headerName: "Title",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 350,
    },

    {
      field: "points",
      headerName: "Stars",
      filterable: false,
      sortable: false,
      width: 100,
    },

    {
      field: "image",
      headerName: "Thumbnail Image",
      filterable: false,
      sortable: false,
      width: 250,
      renderCell: (params) => (
        <Box sx={{ py: 1 }}><img width={200} height={100} src={params.row.banner} alt='banner image' /></Box>)
    },
    {
      field: "category",
      headerName: "Category",
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === 'equals' | operator.value === 'contains',
      ),
      sortable: false,
      width: 150,
    },
    {
      field: "update_schoolresource",
      headerName: "Update School Processes",
      filterable: false,
      sortable: false,
      width: 170,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => navigate(`/edit-school-resource/${row.id}`)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "delete_schoolresource",
      headerName: "Delete School Processes",
      filterable: false,
      sortable: false,
      width: 250,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            endIcon={<DeleteOutlineIcon />}
            color="error"
            variant="outlined"
            style={{ marginLeft: "5px" }}
            onClick={() => {
              setNotSelectedProductModel(true);
              setDeleteId(row.id);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];
  const [queryOptions, setQueryOptions] = useState({});

  const onFilterChange = useCallback((filterModel) => {
    setQueryOptions({ filterModel: { ...filterModel } });
  }, []);
  const addToCartHandler = async () => {
    navigate("/add-school-resource");
  };

  useEffect(() => {
    const filterModel = queryOptions?.filterModel;
    const items = filterModel?.items;
    const firstItem = items && items.length > 0 ? items[0] : {};
    const quickFilterValues = filterModel?.quickFilterValues;

    const query = {
      page: paginationModel?.page + 1,
      page_size: paginationModel?.pageSize,
      field: firstItem.field || '',
      operator: firstItem.operator || (quickFilterValues && "contains") || '',
      value: firstItem.value || (quickFilterValues && quickFilterValues.toString().replaceAll(",", " ")) || ''
    };
    dispatch(schoolResourceApi(query));
    dispatch(resetschoolResource());
  }, [dispatch, queryOptions, paginationModel]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>School Processes List</Typography>
        </Stack>

        <Stack sx={{ my: 2 }} justifyContent="space-between" direction="row">
          <Stack direction="row" spacing={1}>
            <Button
              onClick={addToCartHandler}
              variant="contained"
            >
              Add New School Processes
            </Button>
            <Button
              onClick={() => setPodcastBulk(true)}
              variant="contained"
            >
              Add Bulk School Processes
            </Button>
          </Stack>
        </Stack>

        <Paper elevation={0}>
          <DataGridComponent
            loading={loading}
            rowCount={total}
            pageSize={paginationModel?.pageSize}
            onPageSizeChange={(newPageSize) => {
              setPaginationModel({
                ...paginationModel,
                pageSize: newPageSize,
              })
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            sx={{
                    height: 500 
                }}
            getRowId={(row) => row.id}
            rows={result}
            columns={columns}
            onFilterModelChange={onFilterChange}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: (props) =>
                (CustomGridToolbar({ ...props, moduleType: "schoolprocessManagement", queryOptions: queryOptions, paginationModel: paginationModel, isDownload: isDownload, setIsDownload: setIsDownload, apiUrl: apiRoutes.schoolProcessList })),
            }}
            slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>

      </Body>

      <DeleteSchoolResource
        open={notSelectedProductModel}
        setOpen={setNotSelectedProductModel}
        setSelectedRow={setSelectedRow}
        id={deleteId}
      />
      <BulkSchoolResource
        open={podcastBulk}
        setOpen={setPodcastBulk}

      />
      <BackdropUI open={isDownload} />

    </>
  );
}
