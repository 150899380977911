export const admin = `api/v1/admin`;
export const globals = `api/v1/globals`;
export const publicapi = `api/v1`;
export const users = `${admin}/users`;
export const userContent = `${admin}/user-content/`;
export const contentReport = `${admin}/content-report/`;
export const category = `${admin}/category`;
export const categories = `${admin}/categories`;
export const classes = `${admin}/classes`;
export const splash = `${admin}/splash-screen`;
export const course = `${admin}/course`;
export const faq = `${admin}/faq`;
export const courseSection = `${course}/section`;
export const courseCertificate = `${course}/certificate`;
export const courseSubSection = `${course}/sub-section`;
export const courseScorm = `${course}/upload-scorm`;
export const courseUnit = `${course}/unit`;
export const courseUnitPublish = `${course}/unit-publish`;
export const courseUploadVideo = `${course}/upload-video`;
export const difficultyLevel = `${admin}/difficulty-level`;
export const teachingKit = `${admin}/teaching-kits`;
export const entryExit = `${teachingKit}/entry-exit`;
export const homeWork = `${teachingKit}/home-work`;
export const projectBased = `${teachingKit}/project-based`;
export const questionBank = `${teachingKit}/question-bank`;
export const schoolProcess = `${teachingKit}/school-process`;
export const teachingVideos = `${teachingKit}/teaching-videos`;
export const learningMaterial = `${teachingKit}/learning-material`;

export const impactStory = `${admin}/impact-stories`;
export const notification = `${admin}/notification`;
export const cohort = `${notification}/cohort`;
export const cohortUser = `${notification}/cohort-user`;
export const cohortDetail = `${notification}/cohort-details`;
export const partners = `${admin}/partners`;
export const podcasts = `${admin}/podcasts`;
export const quiz = `${admin}/quiz`;
export const passingCriteria = `${admin}/passing-criteria`;
export const shortVideos = `${admin}/short-videos`;
export const subject = `${admin}/subject`;
export const sundayPolls = `${admin}/sunday-polls`;
export const topics = `${admin}/topics`;
export const status = `${admin}/status`;
export const webinars = `${admin}/webinars`;
export const publish = `${admin}/published`;
export const reportDownload = `${admin}/report-download/`;

const apiRoutes = {
    //Admin
    adminAction: (action, slash) => `${admin}/${action}${slash ? "/" : ''}`,
    analytics: (type) => `${admin}/analytics/${type}/`,
    userList: `${users}/`,
    login: `${admin}/login/`,
    categoryList: `${category}/`,
    categoryAction: (action, slash) => `${category}/${action}${slash ? "/" : ''}`,
    globalsAction: (action, slash) => `${globals}/${action}${slash ? "/" : ''}`,
    publicapiAction: (action, slash) => `${publicapi}/${action}${slash ? "/" : ''}`,
    categoriesList: `${categories}/`,
    categoriesAction: (action, slash) => `${categories}/${action}${slash ? "/" : ''}`,
    classList: `${classes}/`,
    classesAction: (action, slash) => `${classes}/${action}${slash ? "/" : ''}`,
    splashScreenList: `${splash}/`,
    splashScreenAction: (action, slash) => `${splash}/${action}${slash ? "/" : ''}`,
    courseList: `${course}/list/`,
    courseAction: (action, slash) => `${course}/${action}${slash ? "/" : ''}`,
    faqList: `${faq}/`,
    faqAction: (action, slash) => `${faq}/${action}${slash ? "/" : ''}`,
    courseSec: `${courseSection}`,
    courseSecAction: (action, slash) => `${courseSection}/${action}${slash ? "/" : ''}`,
    courseSubSec: `${courseSubSection}`,
    courseSubSecAction: (action, slash) => `${courseSubSection}/${action}${slash ? "/" : ''}`,
    recentUsers: `${admin}/recent_users/`,
    usersAction: (action, slash) => `${users}/${action}${slash ? "/" : ''}`,
    courseScormList: `${courseScorm}`,
    courseScormAction: (action, slash) => `${courseScorm}/${action}${slash ? "/" : ''}`,
    courseUnit: `${courseUnit}/`,
    courseUnitAction: (action, slash) => `${courseUnit}/${action}${slash ? "/" : ''}`,
    courseUnitUploadVideo: `${courseUploadVideo}/`,
    courseUnitUploadVideoAction: (action, slash) => `${courseUploadVideo}/${action}${slash ? "/" : ''}`,
    courseUnitPublishList: `${courseUnitPublish}/`,
    courseUnitPublishAction: (action, slash) => `${courseUnitPublish}/${action}${slash ? "/" : ''}`,
    difficultyLevelList: `${difficultyLevel}/`,
    difficultyLevelAction: (action, slash) => `${difficultyLevel}/${action}${slash ? "/" : ''}`,
    entryExitList: `${entryExit}`,
    entryExitAction: (action, slash) => `${entryExit}/${action}${slash ? "/" : ''}`,
    holidayHomeWorkList: `${homeWork}/`,
    holidayHomeWorkAction: (action, slash) => `${homeWork}/${action}${slash ? "/" : ''}`,
    impactStoryList: `${impactStory}/story-list`,
    impactStoryAction: (action, slash) => `${impactStory}/${action}${slash ? "/" : ''}`,
    cohortUserList: `${cohortUser}/`,
    cohortUserAction: (action, slash) => `${cohortUser}/${action}${slash ? "/" : ''}`,
    cohortList: `${cohort}/`,
    cohortAction: (action, slash) => `${cohort}/${action}${slash ? "/" : ''}`,
    cohortDetailsList: `${cohortDetail}/`,
    cohortDetailsAction: (action, slash) => `${cohortDetail}/${action}${slash ? "/" : ''}`,
    notificationList: `${notification}/`,
    notificationAction: (action, slash) => `${notification}/${action}${slash ? "/" : ''}`,
    partnersList: `${partners}/`,
    partnersAction: (action, slash) => `${partners}/${action}${slash ? "/" : ''}`,
    projectBasedList: `${projectBased}/`,
    projectBasedAction: (action, slash) => `${projectBased}/${action}${slash ? "/" : ''}`,
    podcastsList: `${podcasts}/list`,
    podcastsAction: (action, slash) => `${podcasts}/${action}${slash ? "/" : ''}`,

    questionBankList: `${questionBank}/`,
    questionBankAction: (action, slash) => `${questionBank}/${action}${slash ? "/" : ''}`,

    quizList: `${quiz}/`,
    quizAction: (action, slash) => `${quiz}/${action}${slash ? "/" : ''}`,

    schoolProcessList: `${schoolProcess}`,
    schoolProcessAction: (action, slash) => `${schoolProcess}/${action}${slash ? "/" : ''}`,

    courseCertificateList: `${courseCertificate}`,
    courseCertificateAction: (action, slash) => `${courseCertificate}/${action}${slash ? "/" : ''}`,

    passingCriteriaList: `${passingCriteria}/`,
    passingCriteriaAction: (action, slash) => `${passingCriteria}/${action}${slash ? "/" : ''}`,

    shortVideosList: `${shortVideos}/`,
    shortVideosAction: (action, slash) => `${shortVideos}/${action}${slash ? "/" : ''}`,
    shortVideosPublish:`${publish}`,

    subjectList: `${subject}/`,
    subjectAction: (action, slash) => `${subject}/${action}${slash ? "/" : ''}`,

    sundayPollsList: `${sundayPolls}/poll-list`,
    sundayPollsAction: (action, slash) => `${sundayPolls}/${action}${slash ? "/" : ''}`,

    teachingVideosList: `${teachingVideos}`,
    teachingVideosAction: (action, slash) => `${teachingVideos}/${action}${slash ? "/" : ''}`,

    learningMaterialList: `${learningMaterial}/`,
    learningMaterialAction: (action, slash) => `${learningMaterial}/${action}${slash ? "/" : ''}`,

    topicsList: `${topics}/`,
    topicsAction: (action, slash) => `${topics}/${action}${slash ? "/" : ''}`,

    statusList: `${status}/`,
    statusAction: (action, slash) => `${status}/${action}${slash ? "/" : ''}`,

    webinarsList: `${webinars}/`,
    webinarsAction: (action, slash) => `${webinars}/${action}${slash ? "/" : ''}`,

};

export default apiRoutes;
