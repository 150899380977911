import { BrowserRouter, Route, Routes } from "react-router-dom";
import NotRoute from "../view/404Route";
import ForgetPassword from "../view/auth/ForgetPassword";
import Login from "../view/auth/Login";
import CategoryEdit from "../view/category/categoryEdit";
import CategoryList from "../view/category/categoryList";
import AddSplashScreen from "../view/cms/AddSplashScreen";
import EditPage from "../view/cms/EditPage";
import ManageCms from "../view/cms/ManageCms";
import ManageSplashScreen from "../view/cms/ManageSplashScreen";
import AddCourse from "../view/courses/AddCourse";
import AddCourseSubSection from "../view/courses/AddCourseSubSection";
import AddCourseUnit from "../view/courses/AddCourseUnit";
import Courses from "../view/courses/ManageCourses";
import UnitList from "../view/courses/UnitList";
import EditEntryExit from "../view/entry-exit-question/edit-entry-exit-question";
import EditTlm from "../view/tlm/editTlm";
import EditPbl from "../view/pbl/editPbl";
import EditHolidayHomework from "../view/holiday-homework/editHolidayHomework";
import UploadCertificate from "../view/entry-exit-question/uploadCertificate";

import ManageEntryExit from "../view/entry-exit-question/entry-exit-question";
import AddFaq from "../view/faq/editFaq";
import FaqListing from "../view/faq/faqListing";
import AnalyticsListing from "../view/analytics/AnalyticsListing";
import Managehhw from "../view/holiday-homework/manageHoliday";
import Home from "../view/home";
import EditImpact from "../view/impact/edit-impact-stories";
import ManageImpact from "../view/impact/impact-stories-management";
import AddPartner from "../view/partner/AddPartner";
import ManagePartners from "../view/partner/ManagePartner";
import ManagePbl from "../view/pbl/managePbl";
import AddPodcast from "../view/podcast/AddPodcast";
import ManagePodcast from "../view/podcast/ManagePodcast";
import AddQuiz from "../view/quizzes/addQuiz";
import ManageQuiz from "../view/quizzes/manageQuiz";
import AddSchoolResource from "../view/school-resources/AddSchoolResource";
import ManageSchoolResource from "../view/school-resources/ManageSchoolResource";
import AddShortVideo from "../view/shortVideos/AddShortVideos";
import ManageShortVideo from "../view/shortVideos/ManageShortVideo";
import SundayPollAnalytics from "../view/sundayPoll/Analytics";
import EditPoll from "../view/sundayPoll/EditPoll";
import ManagePoll from "../view/sundayPoll/managePoll";
import SupportListing from "../view/support/supportListing";
import AddTeachingVideo from "../view/teaching-video/AddTeachingVideo";
import ManageTeachingVideo from "../view/teaching-video/ManageTeachingVideo";
import ManageTlm from "../view/tlm/manageTlm";
import ManageUser from "../view/user/ManageUser";
import AddWebinars from "../view/webinars/AddWebinars";
import ManageWebinar from "../view/webinars/ManageWebinar";
import ManageQnsBank from "../view/question-bank/ManageQnsBank";
import AddQnsBank from "../view/question-bank/AddQnsBank";
import DeleteQnsBank from "../view/question-bank/DeleteQnsBank";
import ManageNotifications from "../view/notifications/notification-list";
import PushNotifications from "../view/notifications/push-notification";
import ClassEdit from "../view/class/ClassEdit";
import ClassList from "../view/class/ClassList";
import SettingsList from "../view/settings/SettingsList";
import SettingsEdit from "../view/settings/SettingsEdit";
import SubjectEdit from "../view/subject/SubjectEdit";
import SubjectList from "../view/subject/SubjectList";
import TopicsEdit from "../view/topics/TopicsEdit";
import TopicsList from "../view/topics/TopicsList";
import DifficultyLevelEdit from "../view/difficultyLevel/DifficultyLevelEdit";
import DifficultyLevelList from "../view/difficultyLevel/DifficultyLevelList";
import InterestedUser from "../view/webinars/InterestedUser";
import ManageNotificationCohort from "../view/notification-cohort/notification-cohort-history";
import ManageCohortUser from "../view/notification-cohort/user-list";
import AddCohortNotifications from "../view/notification-cohort/add-cohort";
import AuthGuard from "../components/AuthGuard";
export default function MainRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route
            path="/"
            element={
              <Home />
            }
          />
          <Route
            path="/home"
            element={
              <Home />
            }
          />
          {/* user  */}
          <Route
            path="/user-management"
            element={
              <ManageUser />
            }
          />

          {/* CMS  */}
          <Route
            path="/manage-pages"
            element={
              <ManageCms />
            }
          />
          <Route
            path="/handle-splash-screen"
            element={
              <AddSplashScreen />
            }
          />
          <Route
            path="/manage-splash-screen"
            element={
              <ManageSplashScreen />
            }
          />
          <Route
            path="/edit-page/:name"
            element={
              <EditPage />
            }
          />
          <Route
            path="/edit-splash-screen/:id"
            element={
              <AddSplashScreen />
            }
          />

          {/* courses */}
          <Route
            path="/courses"
            element={
              <Courses />
            }
          />
          <Route
            path="/edit-course/:id"
            element={
              <AddCourse />
            }
          />
          <Route
            path="/add-courses"
            element={
              <AddCourse />
            }
          />
          <Route
            path="/edit-course/sub-section"
            element={
              <AddCourseSubSection />
            }
          />
          <Route
            path="/edit-course/sub-section/units"
            element={
              <AddCourseUnit />
            }
          />
          <Route
            path="/edit-course/sub-section/units/list"
            element={
              <UnitList />
            }
          />

          {/* podcast */}
          <Route
            path="/podcasts"
            element={
              <ManagePodcast />
            }
          />
          <Route
            path="/add-podcast"
            element={
              <AddPodcast />
            }
          />
          <Route
            path="/edit-podcast/:id"
            element={
              <AddPodcast />
            }
          />

          {/* webinar */}
          <Route
            path="/webinars"
            element={
              <ManageWebinar />
            }
          />
          <Route
            path="/add-webinar"
            element={
              <AddWebinars />
            }
          />
          <Route
            path="/edit-webinar/:id"
            element={
              <AddWebinars />
            }
          />
          <Route
            path="/webinar-interested-users/:id"
            element={
              <InterestedUser />
            }
          />

          {/* short Videos */}
          <Route
            path="/short-videos"
            element={
              <ManageShortVideo />
            }
          />
          <Route
            path="/add-short-video"
            element={
              <AddShortVideo />
            }
          />
          <Route
            path="/edit-short-video/:id"
            element={
              <AddShortVideo />
            }
          />

          {/* teaching  Videos */}
          <Route
            path="/teaching-videos"
            element={
              <ManageTeachingVideo />
            }
          />
          <Route
            path="/add-teaching-video"
            element={
              <AddTeachingVideo />
            }
          />
          <Route
            path="/edit-teaching-video/:id"
            element={
              <AddTeachingVideo />
            }
          />

          {/* school resource */}
          <Route
            path="/school-resource"
            element={
              <ManageSchoolResource />
            }
          />
          <Route
            path="/add-school-resource"
            element={
              <AddSchoolResource />
            }
          />
          <Route
            path="/edit-school-resource/:id"
            element={
              <AddSchoolResource />
            }
          />

          {/* faq */}
          <Route
            path="/faq"
            element={
              <FaqListing />
            }
          />
          <Route
            path="/add-faq"
            element={
              <AddFaq />
            }
          />
          <Route
            path="/edit-faq/:id"
            element={
              <AddFaq />
            }
          />

          {/* support */}
          <Route
            path="/support-management"
            element={
              <SupportListing />
            }
          />
          <Route
            path="/add-support"
            element={
              <AddPodcast />
            }
          />
          <Route
            path="/edit-support/:id"
            element={
              <AddPodcast />
            }
          />

          {/* category */}
          <Route
            path="/category-management"
            element={
              <CategoryList />
            }
          />
          <Route
            path="/add-category"
            element={
              <CategoryEdit />
            }
          />
          <Route
            path="/edit-category/:id"
            element={
              <CategoryEdit />
            }
          />
          <Route
            path="/banner"
            element={
              <ManageSplashScreen />
            }
          />

          {/* Poll */}
          <Route
            path="/sunday-polls"
            element={
              <ManagePoll />
            }
          />
          <Route
            path="/add-sunday-poll"
            element={
              <EditPoll />
            }
          />
          <Route
            path="/edit-sunday-poll/:id"
            element={
              <EditPoll />
            }
          />
          <Route
            path="/results/:id"
            element={
              <SundayPollAnalytics />
            }
          />

          {/* Impact Stories */}
          <Route
            path="/impact-stories-management"
            element={
              <ManageImpact />
            }
          />
          <Route
            path="/impact-story-details/:id"
            element={
              <EditImpact />
            }
          />
          {/* Entry Exit Question */}
          <Route
            path="/entry-exit-management"
            element={
              <ManageEntryExit />
            }
          />
          <Route
            path="/entry-exit-details/:id"
            element={
              <EditEntryExit />
            }
          />
          <Route
            path="/add-entry-exit-details"
            element={
              <EditEntryExit />
            }
          />
          {/* Partners*/}
          <Route
            path="/partners-management"
            element={
              <ManagePartners />
            }
          />
          <Route
            path="/add-partners"
            element={
              <AddPartner />
            }
          />
          <Route
            path="/edit-partners/:id"
            element={
              <AddPartner />
            }
          />

          {/* Teaching Kit*/}
          <Route
            path="/pbl-management"
            element={
              <ManagePbl />
            }
          />
          <Route
            path="/add-pbl-management"
            element={
              <EditPbl />
            }
          />
          <Route
            path="/edit-pbl-management/:id"
            element={
              <EditPbl />
            }
          />
          <Route
            path="/holiday-homework-management"
            element={
              <Managehhw />
            }
          />
          <Route
            path="/add-holiday-homework-management"
            element={
              <EditHolidayHomework />
            }
          />
          <Route
            path="/edit-holiday-homework-management/:id"
            element={
              <EditHolidayHomework />
            }
          />

          <Route
            path="/tlm-management"
            element={
              <ManageTlm />
            }
          />
          <Route
            path="/add-tlm-management"
            element={
              <EditTlm />
            }
          />
          <Route
            path="/edit-tlm-management/:id"
            element={
              <EditTlm />
            }
          />

          {/* Poll */}
          <Route
            path="/question-bank"
            element={
              <ManageQnsBank />
            }
          />
          <Route
            path="/question-bank/add"
            element={
              <AddQnsBank />
            }
          />
          <Route
            path="/question-bank/edit/:id"
            element={
              <AddQnsBank />
            }
          />
          <Route
            path="/question-bank/delete/:id"
            element={
              <DeleteQnsBank />
            }
          />

          {/* Quizzes */}
          <Route
            path="/quizzes"
            element={
              <ManageQuiz />
            }
          />
          <Route
            path="/add-quiz"
            element={
              <AddQuiz />
            }
          />
          <Route
            path="/edit-quiz/:id"
            element={
              <AddQuiz />
            }
          />

          {/* Analytics */}
          <Route
            path="/get-analytics"
            element={
              <AnalyticsListing />
            }
          />

          {/* subject */}
          <Route
            path="/subject-management"
            element={
              <SubjectList />
            }
          />
          <Route
            path="/add-subject"
            element={
              <SubjectEdit />
            }
          />
          <Route
            path="/edit-subject/:id"
            element={
              <SubjectEdit />
            }
          />

          {/* class */}
          <Route
            path="/class-management"
            element={
              <ClassList />
            }
          />
          <Route
            path="/add-class"
            element={
              <ClassEdit />
            }
          />
          <Route
            path="/edit-class/:id"
            element={
              <ClassEdit />
            }
          />

          {/* settings */}
          <Route
            path="/settings"
            element={
              <SettingsList />
            }
          />
          <Route
            path="/edit-settings/:id"
            element={
              <SettingsEdit />
            }
          />

          {/* difficulty level */}
          <Route
            path="/difficulty-level-management"
            element={
              <DifficultyLevelList />
            }
          />
          <Route
            path="/add-difficulty-level"
            element={
              <DifficultyLevelEdit />
            }
          />
          <Route
            path="/edit-difficulty-level/:id"
            element={
              <DifficultyLevelEdit />
            }
          />
          {/* difficulty level */}
          <Route
            path="/notification-cohort-management"
            element={
              <ManageNotificationCohort />
            }
          />
          <Route
            path="/add-notification-cohort"
            element={
              <AddCohortNotifications />
            }
          />
          <Route
            path="/edit-notification-cohort/:id"
            element={
              <AddCohortNotifications />
            }
          />
          <Route
            path="/notification-cohort/:id/user-list"
            element={
              <ManageCohortUser />
            }
          />
          {/* topics */}
          <Route
            path="/topics-management"
            element={
              <TopicsList />
            }
          />
          <Route
            path="/add-topics"
            element={
              <TopicsEdit />
            }
          />
          <Route
            path="/edit-topics/:id"
            element={
              <TopicsEdit />
            }
          />
          <Route
            path="/upload-certificate/:id"
            element={
              <UploadCertificate />
            }
          />
          {/* Notifications */}
          <Route path="/notifications" element={<ManageNotifications />} />
          <Route path="/push-notification" element={<PushNotifications />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        <Route path="*" element={<NotRoute />} />
      </Routes>
    </BrowserRouter>
  );
}
