import { Box, Button, Paper, Stack, Typography } from "@mui/material";
import { GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { HiTemplate } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import NodataImg from "../../assets/images/noData.png";
import Appbar from "../../components/Appbar";
import Body from "../../components/Body";
import Drawer from "../../components/Drawer";
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import { settingApi } from "../../store/settings/settingList";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getCertificateApi } from "../../store/settings/certificate";
import DataGridComponent from "../../components/DataGridComponent";

export default function SettingsList() {
  const [tableData, setTableData] = useState([])
  const settingList = useSelector((state) => state?.settingList);
  const certificatesDetails = useSelector((state) => state?.certificatesDetails);
  const { result, loading } = settingList;
  const { result: certificateResults, loading: certifcateLoading } = certificatesDetails;
  const addDatatoState = () => {
    if (result?.length > 0) {
      if (certificateResults?.length > 0) {
        let customData = [...result].map(rep => {
          return (
            {
              ...rep,
              html_text: certificateResults.find(rek => rek.certificate_type === rep.title)?.html_text ?? ''
            }
          )
        })
        setTableData(customData)

      }
      else {
        setTableData(result)

      }
    }
  }
  useEffect(() => {
    addDatatoState()
  }, [result, certificateResults])
  const [deleteId, setDeleteId] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedRow, setSelectedRow] = useState([]);
  const [notSelectedProductModel, setNotSelectedProductModel] = useState(false);

  const handleNavigation = async (id) => {
    navigate(`/edit-settings/${id}`);
  };
  const handleNavigationcertificate = async (id) => {
    navigate(`/upload-certificate/${id}`);
  };
  const columns = [
    {
      field: "title",
      headerName: "Setting Name",
      width: 400,
    },
    {
      field: "min_percent",
      headerName: "Min Percentage",
      width: 400,
    },
    {
      field: "max_percent",
      headerName: "Max Percentage",
      width: 400,

    },
    {
      field: "update_setting",
      headerName: "Update Settings",
      width: 150,
      disableExport: true,
      renderCell: (row) => (
        <>
          <Button
            color="info"
            endIcon={<EditIcon />}
            label="Available" variant="outlined"
            onClick={() => handleNavigation(row.id)}
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      field: "certificate_setting",
      headerName: "Certificate",
      width: 200,
      disableExport: true,
      renderCell: (row) => (
        <>
          {/* {console.log('row',row)} */}

          <Button
            color="info"
            endIcon={<UploadFileIcon />}
            label="Available" variant="outlined"
            onClick={() => handleNavigationcertificate(row.row.title)}
          >
            Upload Certificate
          </Button>
        </>
      ),
    }


  ];

  useEffect(() => {
    dispatch(settingApi());
    dispatch(getCertificateApi());


  }, [dispatch]);

  return (
    <>
      <Appbar />
      <Drawer />
      <Body>


        <Stack direction="row" spacing={1} sx={{ my: 2 }} alignItems="center">
          <HiTemplate size={30} />
          <Typography>Settings</Typography>
        </Stack>

        <Paper elevation={0}>
          <DataGridComponent
            sx={{
                    height: 500 
                }}
            getRowId={(row) => row.id}
            rows={tableData}
            columns={columns}
            loading={loading}
            slots={{
              noRowsOverlay: () => (
                <Box className="noData">
                  <img src={NodataImg} alt="no data found" />
                </Box>
              ),
              toolbar: GridToolbar,
            }}
            slotProps={{
loadingOverlay: {
      variant: 'linear-progress',
      noRowsVariant: 'linear-progress',
    },
              toolbar: {
                showQuickFilter: true,
              },
            }}
          />
        </Paper>

      </Body>
    </>
  );
}
