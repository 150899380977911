import React, { createContext, useState, useEffect, useCallback } from 'react'
import { currentUserAPI } from "../store/auth/current-user";
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types'


export const AuthContext = createContext();
const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem("access_token", accessToken);
    } else {
        localStorage.removeItem("access_token");
    }
};
function checkLogin() {
    const accessToken = window.localStorage.getItem("access_token");
    return accessToken ? true : false;
}

export default function AuthContextProvider({ children }) {
    const dispatch = useDispatch()

    const [isLogin, setIsLogin] = useState(checkLogin());
    const [userData, setUserData] = useState({});
    const getUserProfileDatahandler = useCallback(() => {
        dispatch(currentUserAPI()).then((action) => {
            if (!action.type.includes("rejected")) {
                setUserData(action.payload.result)
            } else if (action.type.includes("rejected")) {
                localStorage.removeItem("access_token");
                setIsLogin(false)
                setUserData()
            }
        })
    }, [])
    useEffect(() => {
        if (window.localStorage.getItem("access_token")) {
            getUserProfileDatahandler(window.localStorage.getItem("access_token"));
        }
    }, [window.localStorage.getItem("access_token"), getUserProfileDatahandler]);

    return (
        <AuthContext.Provider
            value={{
                userLoggedIn: isLogin,
                setIsLogin: (data) => setIsLogin(data),
                checkLogin: (data) => checkLogin(data),
                userLogIn: (type, data) => {
                    setSession(data);
                    setIsLogin(type);
                },
                userData
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}


AuthContextProvider.propTypes = {
    children: PropTypes.any,
};